import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import MultiVideoPlayer from '../../../../components/MultiVideoPlayer';
import Icon from '../../../../components/Icon';

const FinalistPresentations2015 = () => (
  <Layout
    title="3M 2015 YSC Finalist Presentations"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            3M 2015 YSC Finalist Presentations
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">3M 2015 YSC Finalist Presentations</h1>
            {/* Video Container */}
            <div className="video-container__with-pager multivideo">
              <Link
                to="../3M-2016-YSC-Finalist-Presentations"
                className="previous"
              >
                <Icon name="arrowleftgray" />
              </Link>
              <MultiVideoPlayer
                videos={[
                  // {
                  //guidSrc: '',
                  //poster: '2015-finalist-thumbnail-opening-remarks.jpg',
                  //title: 'Welcome and Opening Remarks',
                  //description: '',
                  //className: 'de-vid',
                  //},
                  {
                    guidSrc: 'ae5e8aa3-7ef6-4c8d-b3b1-65d27dc389b3',
                    poster: '2015-finalist-thumbnail-opening-remarks.jpg',
                    title: 'Meet Hannah, the 2015 Challenge Winner',
                    description: 'Meet Hannah, the 2015 Challenge Winner',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'f3199238-83b5-49f9-ad53-4763d98dad0d',
                    poster: '2015-finalist-thumbnail-raqhav-ganesh.jpg',
                    title: '3M 2015 YSC Finalist Raqhav Ganesh',
                    description: 'View 2015 Challenge Finalist Raqhav Ganesh',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '3d7d8ab9-5b6f-47cc-8a2d-01917d2ba167',
                    poster: '2015-finalist-thumbnail-conner-pettit.jpg',
                    title: '3M 2015 YSC Finalist Conner Pettit',
                    description: 'View 2015 Challenge Finalist Conner Pettit',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'a42a19f0-e05f-413e-ae96-c7892ad7b1fb',
                    poster: '2015-finalist-thumbnail-peter-finch.jpg',
                    title: '3M 2015 YSC Finalist Peter Finch',
                    description: 'View 2015 Challenge Finalist Peter Finch',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '71334c28-22c6-4515-ae4a-87475b9ac194',
                    poster: '2015-finalist-thumbnail-amuyla-garimella.jpg',
                    title: '3M 2015 YSC Finalist Amulya Garimella',
                    description:
                      'View 2015 Challenge Finalist Amulya Garimella',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '3c8c8e2b-4a23-4053-a28a-c6015f11ba92',
                    poster: '2015-finalist-thumbnail-arthur-frigo.jpg',
                    title: '3M 2015 YSC Finalist Arthur Frigo, III',
                    description:
                      'View 2015 Challenge Finalist Arthur Frigo, III',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'a8da919f-cf0f-4024-918b-16a26cbd69c9',
                    poster: '2015-finalist-thumbnail-iris-gupta.jpg',
                    title: '3M 2015 YSC Finalist Iris Gupta',
                    description: 'View 2015 Challenge Finalist Iris Gupta',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'd16f16dc-4d07-4bcf-8fb7-a2d69b0c0781',
                    poster: '2015-finalist-thumbnail-sanjana-shah.jpg',
                    title: '3M 2015 YSC Finalist Sanjana Shah',
                    description: 'View 2015 Challenge Finalist Sanjana Shah',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'dc817d71-0fc9-4036-baa3-dbdb55381de2',
                    poster: '2015-finalist-thumbnail-krishna-reddy.jpg',
                    title: '3M 2015 YSC Finalist Krishna Reddy',
                    description: 'View 2015 Challenge Finalist Krishna Reddy',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '83c73236-5dad-43dd-942c-d10356e00163',
                    poster: '2015-finalist-thumbnail-closing-remarks.jpg',
                    title: 'Closing Remarks',
                    description: 'Closing Remarks',
                    className: 'de-vid',
                  },
                ]}
              />
              <Link to="../3M-2014-YSC-Finalist-Presentations" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <p className="text-26">
              Hear from the <strong>2016 Top Young Scientist Finalists</strong>{' '}
              as each presents their entry idea for the Annual Young Scientist
              Challenge.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FinalistPresentations2015;
